function checkExtjs(){
    alert("slebew");
}

function flyTo(map, latlon) {
    map.flyTo(latlon, 11);
}

function flyUp(map) {
    let latlon = ['-8.4730301', '115.1621781'];
    let zoom = 10;
    map.flyTo(latlon, zoom);
    map.closePopup();
}

function getDetailTooltip(html, latlng, map){
    L.popup({'minWidth':'254','maxHeight':'300'}).setLatLng(latlng).setContent(html).openOn(map);
}
